<template>
  <div>
    <login-box />
  </div>
</template>
<script>
import LoginBox from '@/components/Auth/Login'
export default {
  components: {
    LoginBox,
  },
}
</script>
